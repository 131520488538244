import { useEffect } from 'react';
import type { IconType } from '~/components/ui/Icons/Icons';
import SVGIcon from '~/components/ui/Icons/SVGIcon';

type TProps = {
  text: string;
  type: 'success' | 'error';
  show: boolean;
  onHide?: () => void;
};

const backGroundClass = {
  success: 'bg-success-100',
  error: 'bg-error-100',
};

const borderClass = {
  success: 'border-success-500',
  error: 'border-error-500',
};

const icon = {
  success: 'check-circle',
  error: 'alert-circle',
};

const iconFill = {
  success: '#35660D',
  error: '#761f1f',
};

const ModalToast = ({ text, type, show, onHide }: TProps) => {
  useEffect(() => {
    if (show && onHide) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [show, onHide]);

  if (!show) return <></>;

  return (
    <div
      className={`modal-toast mb-6 p-3 position-fixed bottom-0 rounded-1 fo-border-sm ${borderClass[type]} ${backGroundClass[type]}`}
    >
      <SVGIcon
        className="me-2"
        iconType={icon[type] as IconType}
        fill={iconFill[type]}
      />
      <div className="text-2 -regular">{text}</div>
    </div>
  );
};

export default ModalToast;

import type { RefObject } from 'react';
import { useEffect } from 'react';

// to use this hook, simply put the image source as data-src instead of src (src can still be used to have a placeholder image).
// for the imageWrapperRef, pass a ref to the element wrapping the image (or multiple images in case of a carousel)
const useLazyLoad = (
  imageWrapperRef: RefObject<HTMLElement>,
  enabled: boolean = true,
  rootMargin: string = '400px 0px',
  threshold: number = 0,
): void => {
  useEffect(() => {
    if (!enabled || !imageWrapperRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const images = imageWrapperRef.current?.querySelectorAll('img[data-src]');
            images?.forEach((img) => {
              const lazyImage = img as HTMLImageElement;
              if (lazyImage.dataset.src) {
                lazyImage.src = lazyImage.dataset.src;
              }
            });
          }
        });
      },
      {
        root: null,
        threshold: threshold,
        rootMargin: rootMargin,
      },
    );

    observer.observe(imageWrapperRef.current);

    return () => {
      observer.disconnect();
    };
  }, [imageWrapperRef, enabled, threshold, rootMargin]);
};

export default useLazyLoad;

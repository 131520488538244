import { useParams, useSearchParams } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { currencyDisplay } from '~/components/utils/text';
import type { OfficeDataType } from '~/types/offices';
import mixpanelTrack from '~/hooks/useMixPanel';
import SliderImages from './SliderImages';
import {
  addDashToUuid,
  cityText,
  getUuidLabel,
  officeCapacityLabel,
  officeSurfaceLabel,
} from '~/components/utils/officeData';
import BookmarkNotes from '../../BookmarkNotes';
import ShareLinkModal from '~/components/pages/office/ShareLinkModal';
import { Button } from '../../buttons/Button';
import { useLanguageParam } from '~/config/i18n';
import Badge, { Variant } from '../../badges/Badge';
import { DateTime } from 'luxon';
import { isCapacityFixed, isFlexibilityFixed } from '~/components/pages/office/utils';

type Props = {
  office: OfficeDataType;
  extraClass?: string;
  onMap?: boolean;
  lazyLoadImages?: boolean;
  showRef?: boolean;
  showNotes?: boolean;
  showShare?: boolean;
  simplified?: boolean;
  onCardClick?: () => void;
  onHoveredOffice?: (id: number | null) => void;
};

const OfficeCard = ({
  office,
  extraClass = '',
  onMap = false,
  lazyLoadImages = false,
  showRef = false,
  showNotes = false,
  showShare = false,
  simplified = false,
  onCardClick = undefined,
  onHoveredOffice = () => {},
}: Props) => {
  const { t } = useTranslation();
  const lang = useLanguageParam();
  const { page } = useParams();
  const [searchParams] = useSearchParams();

  const [target, setTarget] = useState('_blank');

  const buildOfficeCardTitle = () => {
    const title = office.title[lang as keyof typeof office.title];

    switch (page) {
      case 'flexible-offices':
        return (
          <>
            <span className="accessible">{t('office.officeCard.titleAddon.flexibleOffice')} | </span>
            {title}
          </>
        );
      default:
        return title;
    }
  };

  const leasingTime = (office: OfficeDataType) =>
    office.allowPartTime
      ? office.partTimeExclusive
        ? t('officeSearch.card.period.partTime')
        : t('officeSearch.card.period.fullPartTime')
      : t('officeSearch.card.period.fullTime');

  const getOfficeUrl = () => {
    let officeUrl = office.url[lang as keyof typeof office.url];
    const searchCapacity = Math.max(
      Number(searchParams.get('guestFilter.min')),
      Number(searchParams.get('guestFilter.max')),
    );

    if (searchCapacity) {
      officeUrl += `?requiredCapacity=${searchCapacity}`;
    }

    return officeUrl;
  };

  // the price per seat varies if:
  // - the flexibility is not fixed (part-time and full-time or part-time days more than 1)
  // - the capacity is variable and price-flexibility is false
  const variablePricePerSeat =
    !isFlexibilityFixed(office.flexibility, office.partTimeDays) ||
    (!isCapacityFixed(office.minCapacity, office.capacity) && !office.priceFlexibility);

  useEffect(() => {
    setTarget(window.innerWidth <= 768 && !onMap ? '_self' : '_blank');
  }, [onMap]);

  return (
    <>
      <div
        className={`fo-office-card d-flex bg-white overflow-hidden rounded-3 ${extraClass}`}
        key={'card' + extraClass + office.id}
      >
        <a
          className="fo-poppins text-decoration-none fo-office-card__content w-100 h-100"
          href={getOfficeUrl()}
          target={target}
          onMouseEnter={() => onHoveredOffice(office.id)}
          onMouseLeave={() => onHoveredOffice(null)}
          onClick={() => {
            if (onCardClick) onCardClick();
          }}
        >
          <SliderImages
            office={office}
            jsLazyLoadEnabled={lazyLoadImages}
          />
          <div className={` fo-office_card__info ${onMap ? 'p-2' : 'pt-2'}`}>
            <div className="d-flex justify-content-between">
              <span className="text-2 color-primary">
                {t(`office.officeType.${office.officeType}.label`)} · {cityText(office, lang)}
              </span>
              {showRef && <div className="text-2 -regular">{getUuidLabel(addDashToUuid(office.uuid), t)}</div>}
            </div>

            {!onMap && !simplified && (
              <h3 className="text-truncate text-2 -regular color-grey-500 mb-2">{buildOfficeCardTitle()}</h3>
            )}

            <div className={`text-2 color-grey-500 -regular ${onMap ? 'mb-1' : ' mb-2'} ${simplified ? 'mt-2' : ''}`}>
              {officeSurfaceLabel(office, t)} · {leasingTime(office)}
              {office.pro && ` · ${t('officeSearch.card.coworking')}`}
            </div>

            <div className={`text-2 color-grey-500 -regular ${onMap ? 'mb-1' : 'mb-2'}`}>
              <SVGIcon
                className="me-1"
                iconType="number-guests"
                width="16"
                height="16"
                fill="#706D78"
              />
              {officeCapacityLabel(office, t)}
            </div>

            <div
              className={`d-flex justify-content-between align-items-start ${
                onMap || simplified ? '' : 'fo-border-top-sm border-grey-100 pt-2'
              }`}
            >
              <div className="me-2">
                {variablePricePerSeat && <span className="caption -regular">{t('office.officeCard.price.from')} </span>}
                <span className="fo-display-5 -medium">
                  {currencyDisplay(office.cheapestPricePerSeatPerMonth, 0)}
                </span>{' '}
                <span className="color-grey-500 caption -regular">{t('office.officeCard.price.seatPerMonth')}</span>
              </div>
              {(!!office.discountLastMinutePercent || !!office.discountLongTermPercent) && (
                <Badge
                  icon="discount"
                  variant={Variant.ERROR_LIGHT}
                  text={`-${Number(office.discountLastMinutePercent) + Number(office.discountLongTermPercent)}%`}
                />
              )}
            </div>
          </div>
          {searchParams.get('rankingInfo') != null && (
            <div className="bg-grey-200 px-2">
              <div>
                <span>Tier={office.tier} </span>
              </div>
              <div>
                <span>
                  Avail.soon=
                  {DateTime.fromISO(office.earliestStartDate) < DateTime.now().plus({ days: 90 }) ? 'yes' : 'no'}{' '}
                </span>
              </div>
              <div>
                <span>
                  Min.4months=
                  {office.lastAvailabilityDate == null ||
                  DateTime.fromISO(office.lastAvailabilityDate) > DateTime.now().plus({ days: 150 })
                    ? 'yes'
                    : 'no'}{' '}
                </span>
              </div>
              <div>
                <span>Rank={office.rank} </span>
              </div>
              <div>
                <span>TopPick={office.isBestDeal ? 'yes' : 'no'} </span>
              </div>
              <div>
                <span>
                  New=
                  {office.firstPublishedAt &&
                  DateTime.fromISO(office.firstPublishedAt) > DateTime.now().minus({ days: 14 })
                    ? 'yes'
                    : 'no'}{' '}
                </span>
              </div>
              <div>
                <span>
                  EverContacted=
                  {office.averageHostResponsiveness == null || office.averageHostResponsiveness == 0
                    ? 'no'
                    : 'yes'}{' '}
                </span>
              </div>
              <div>
                <span>
                  Responsiveness=
                  {office.averageHostResponsiveness != null ? Math.round(office.averageHostResponsiveness) : ''}{' '}
                </span>
              </div>
              <div>
                <span>
                  FirstPublishedAt=
                  {office.firstPublishedAt && DateTime.fromISO(office.firstPublishedAt).toFormat('yyyy-LL-dd')}{' '}
                </span>
              </div>
            </div>
          )}
        </a>
      </div>
      <div className="pt-1">
        {showNotes && (
          <>
            <BookmarkNotes office={office} />
          </>
        )}
        {showShare && (
          <>
            {
              <Button
                extraClasses="w-100 mt-4"
                data-bs-toggle="modal"
                dataAttributes={{ 'data-bs-toggle': 'modal', 'data-bs-target': `#shareModal_${office.id}` }}
                data-bs-target={`#shareModal_${office.id}`}
                variant="primaryGhost"
                onClick={() => mixpanelTrack('E.share_intent')}
              >
                <SVGIcon
                  className="me-2"
                  iconType="share-2"
                />
                {t('global.btns.share')}
              </Button>
            }
            <ShareLinkModal
              id={office.id}
              url={getOfficeUrl()}
            />
          </>
        )}
      </div>
    </>
  );
};

export default OfficeCard;

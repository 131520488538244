import { useRef, useState, useEffect } from 'react';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import type { OfficeDataType } from '~/types/offices';
import FavoriteBtn from '../FavoriteBtn';
import useLazyLoad from '~/hooks/useLazyLoad';
import { useLanguageParam } from '~/config/i18n';
import { Button } from '../../buttons/Button';
import OfficeAvailabilityBadge from '../../badges/OfficeAvailabilityBadge';
import TopPickBadge from '../../badges/TopPickBadge';

type TProps = {
  office: OfficeDataType;
  jsLazyLoadEnabled?: boolean;
};

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const SliderImages = ({ office, jsLazyLoadEnabled = false }: TProps) => {
  const lang = useLanguageParam();
  const carouselRef = useRef<HTMLDivElement | null>(null);
  useLazyLoad(carouselRef, jsLazyLoadEnabled);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [count, setCount] = useState(1);
  const [slideSize, setSlideSize] = useState<number | null>(null);
  const minSwipeDistance = 20;

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = async () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (slideSize) {
        await sleep(400);
        const scrollPos = carouselRef.current!.scrollLeft;
        const imageIndex = Math.round(scrollPos / slideSize + 1);
        setCount(imageIndex);
      }
    }
  };

  const moveNext = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (slideSize && office!.pictures && count < office!.pictures?.length) {
      carouselRef.current!.scrollLeft += slideSize;

      setCount(count + 1);
    }
  };

  const movePrev = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (slideSize && office!.pictures && count > 1) {
      carouselRef.current!.scrollLeft -= slideSize;
      setCount(count - 1);
    }
  };

  useEffect(() => {
    const slide = document.getElementsByClassName('slide')[0] as HTMLElement;
    setSlideSize(slide?.offsetWidth);
  }, []);

  return (
    <div className="carousel-container">
      <div className="tag-line">
        {office.isBestDeal && <TopPickBadge />}
        <OfficeAvailabilityBadge office={office} />
      </div>
      <FavoriteBtn office={office} />
      <div
        className="scroll-container invisible-scrollbar"
        ref={carouselRef}
      >
        {office.pictures?.map((picture, index) => (
          <img
            key={picture.url}
            {...(jsLazyLoadEnabled
              ? { 'data-src': picture.url, src: '/media-remix/bg-white.png' }
              : { src: picture.url, loading: index === 0 ? 'eager' : 'lazy' })}
            className="fo-mediaCard__img slide img-fluid"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            height={216}
            alt={office.title[lang as keyof typeof office.title]}
          />
        ))}
      </div>
      <Button
        type="button"
        variant="floatingPrimaryLight"
        isIconButton={true}
        extraClasses={`carousel-nav -previous ${count === 1 ? 'd-none' : ''}`}
        onClick={(e) => movePrev(e)}
        aria-label="Previous image"
        name="Previous image"
      >
        <SVGIcon iconType={'chevron-left'} />
      </Button>
      <Button
        type="button"
        variant="floatingPrimaryLight"
        isIconButton={true}
        extraClasses={`carousel-nav -next ${count === office.pictures?.length ? 'd-none' : ''}`}
        onClick={(e) => moveNext(e)}
        aria-label="Next image"
        name="Next image"
      >
        <SVGIcon iconType={'chevron-right'} />
      </Button>
    </div>
  );
};

export default SliderImages;

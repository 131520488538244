import { useTranslation } from 'react-i18next';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import {
  addDashToUuid,
  cityText,
  getCoverUrl,
  getUuidLabel,
  officeCapacityLabel,
  officeSurfaceLabel,
} from '~/components/utils/officeData';
import { currencyDisplay } from '~/components/utils/text';
import { useLanguageParam } from '~/config/i18n';
import type { OfficeDataType } from '~/types/offices';

type Props = {
  office: OfficeDataType;
  extraClass?: string;
};

const SimplifiedOfficeCard = ({ office, extraClass }: Props) => {
  const { t } = useTranslation();
  const lang = useLanguageParam();

  const coverUrl = getCoverUrl(office);

  return (
    <div className={`d-flex flex-row office-card-simplified w-100 ${extraClass}`}>
      {coverUrl && (
        <div className="border-radius-sm overflow-hidden cover-image me-2">
          <img
            src={coverUrl}
            alt={t(`office.officeType.${office.officeType}.label`) + cityText(office, lang)}
          />
        </div>
      )}
      <div className="flex-grow-1">
        <div className="mb-2">
          <h3 className="text-2 color-primary mb-1">
            {t(`office.officeType.${office.officeType}.label`)} • {cityText(office, lang)}
          </h3>
          <div className="text-2 -regular">{getUuidLabel(addDashToUuid(office.uuid), t)}</div>
        </div>
        <div className={`text-2 color-grey-500 -regular mb-2`}>
          {`${officeCapacityLabel(office, t)} • ${officeSurfaceLabel(office, t)}`}
        </div>
        <div className={'d-flex justify-content-between'}>
          <div className="me-2">
            <span className="caption -regular">{t('office.officeCard.price.from')} </span>{' '}
            <span className="fo-display-5 -medium">{currencyDisplay(office.cheapestPricePerSeatPerMonth, 0)}</span>{' '}
            <span className="color-grey-500 caption -regular">{t('office.officeCard.price.seatPerMonth')}</span>
          </div>
          {(!!office.discountLastMinutePercent || !!office.discountLongTermPercent) && (
            <div>
              <div className="badge bg-error-100 color-error-500">
                <SVGIcon
                  iconType="discount"
                  className="me-1"
                  width="16"
                  height="16"
                />
                <span className="text-2">
                  -{Number(office.discountLastMinutePercent) + Number(office.discountLongTermPercent)}%
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimplifiedOfficeCard;

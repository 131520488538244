import { useTranslation } from 'react-i18next';
import Badge, { Variant } from './Badge';

const TopPickBadge = () => {
  const { t } = useTranslation();
  return (
    <Badge
      variant={Variant.PRIMARY}
      text={t('officeSearch.card.tags.topPick')}
    />
  );
};

export default TopPickBadge;

import type { OfficeDataType } from '~/types/offices';
import SimplifiedOfficeCard from './office/officeCard/Simplified';
import GenericModal from './GenericModal';
import { useEffect, useRef, useState } from 'react';
import { useFetcher } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { Button } from './buttons/Button';
import SVGIcon from '../ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';

type Props = {
  office: OfficeDataType;
};

const limit = 250;

declare var bootstrap: any;

const BookmarkNotes = ({ office }: Props) => {
  const fetcher = useFetcher();
  const [error, setError] = useState('');
  const [note, setNote] = useState('');
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const internalNotes = office.internalNotes?.filter((note) => note.content !== '' && note.content !== null);

  const userNote = internalNotes?.find((note) => note.userNote);
  const foNote = internalNotes?.find((note) => !note.userNote)?.content;

  const handleOnChange = (e: any) => {
    const value = e.target.value;
    setNote(value);
    if (value.length > limit) {
      setError(`- ${t('notes.error')}`);
    } else {
      setError('');
    }
  };

  const closeModal = () => {
    const notesModalInstance = bootstrap.Modal.getOrCreateInstance(modalRef.current);
    notesModalInstance.hide();
  };

  const cancel = () => {
    setNote('');
    closeModal();
  };

  const openModal = () => {
    mixpanelTrack('D.favorite_notes_cta');
    const notesModalInstance = bootstrap.Modal.getOrCreateInstance(modalRef.current);
    notesModalInstance.show();
  };

  useEffect(() => {
    if (userNote) {
      setNote(userNote.content);
    } else {
      setNote('');
    }
  }, [userNote]);

  return (
    <div className="mt-2">
      <Button
        variant="primaryOutline"
        onClick={openModal}
        extraClasses="w-100"
      >
        Notes
        {internalNotes && internalNotes?.length > 0 && <span className="notes-badge ms-2">{internalNotes.length}</span>}
      </Button>

      <GenericModal
        open={true}
        modalRef={modalRef}
        title={t('notes.title')}
      >
        <fetcher.Form
          action="/notes"
          method="POST"
          onSubmit={closeModal}
          className="modal-form-content"
        >
          <div className="modal-body">
            <input
              type="hidden"
              name="bookmark_id"
              value={office.bookmarkId || ''}
            />
            <div className="notes">
              <SimplifiedOfficeCard office={office} />
              <div>
                {foNote && (
                  <div className="mt-6 fo-team-notes fo-border-sm border-radius-sm border-accent-500">
                    <div className="p-5 ">{foNote}</div>
                    <div className="color-accent-500 d-flex justify-content-end pe-3 pb-1">
                      <SVGIcon
                        height="20"
                        width="20"
                        iconType="magic"
                      />
                      <span>{t('notes.adminNoteLabel')}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-6">
                <div className="form-floating">
                  <textarea
                    value={note}
                    id={`note-${office.bookmarkId}`}
                    name="content"
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    onChange={handleOnChange}
                  />
                  <label htmlFor={`#note-${office.bookmarkId}`}>{t('notes.label')}</label>
                </div>
                <div className={`mt-2 text-2 -regular ${error ? 'color-error-600' : ''}`}>
                  {t('notes.count', { count: note.length, max: limit })}
                  {error}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              variant="primaryOutline"
              onClick={cancel}
              type="button"
            >
              {t('notes.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!!error}
            >
              {t('notes.save')}
            </Button>
          </div>
        </fetcher.Form>
      </GenericModal>
    </div>
  );
};

export default BookmarkNotes;

import { useFetcher, useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useUser } from '~/modules/useUser';

import mixpanelTrack from '~/hooks/useMixPanel';
import type { CoreOfficeDataType } from '~/types/offices';
import { useLanguageParam } from '~/config/i18n';

type Props = {
  office: CoreOfficeDataType;
  extraClasses?: string;
};

export default function FavoriteBtn({ office, extraClasses = '' }: Props) {
  const lang = useLanguageParam();
  const fetcher = useFetcher();
  const clientStore = useUser();
  const [isFavorite, setIsFavorite] = useState(office.isFavorite || null);
  const [isClicked, setIsClicked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const sendMixpanelEvent = () => {
    mixpanelTrack('0.add_bookmark');
  };

  useEffect(() => {
    if (clientStore) {
      setIsClicked(false);
      setIsFavorite(office.isFavorite || null);
    }
  }, [clientStore, clientStore?.bookmarks, office.isFavorite]);

  const handleSubmit = (e: any, preventDefault: boolean = false, login: boolean = false) => {
    setIsClicked(true);
    if (preventDefault) e.preventDefault();
    e.stopPropagation();
    sendMixpanelEvent();
    if (login) {
      if (location.search.includes('place.bottomLat')) {
        let forwardTo = encodeURIComponent(`${location.pathname}${location.search}`);
        navigate(`/${lang}/signup/guest?bookmarkedOfficeId=${office.id}&source=from_favorite&forwardTo=${forwardTo}`);
      } else {
        // strip away zoom level from the url
        const urlParams = new URLSearchParams(location.search);
        urlParams.delete('zoom');
        let forwardTo = encodeURIComponent(`${location.pathname}${urlParams.toString()}`);
        navigate(`/${lang}/signup/guest?bookmarkedOfficeId=${office.id}&source=from_favorite&forwardTo=${forwardTo}`);
      }
    }
  };

  return (
    <>
      {(!clientStore || !clientStore?.isHost) &&
        (clientStore ? (
          <fetcher.Form
            method="POST"
            action="/manage-bookmark"
          >
            <input
              type="hidden"
              name="officeId"
              value={office.id}
            />
            <input
              type="hidden"
              name="cover"
              value={office.pictures?.[0]?.url || ''}
            />

            <input
              type="hidden"
              name="bookmarkId"
              value={isFavorite || ''}
            />
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              className={`fo-favorite-btn ${
                isFavorite || isClicked ? '-active' : ''
              } ga-button-addToFavourites ${extraClasses}`}
              aria-label="Favorite"
              name="Favorite"
            />
          </fetcher.Form>
        ) : (
          <button
            onClick={(e) => handleSubmit(e, true, true)}
            className={`fo-favorite-btn ga-button-addToFavourites ${extraClasses}`}
            type="button"
            aria-label="Favorite"
            name="Favorite"
          />
        ))}
    </>
  );
}

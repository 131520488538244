import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '~/components/common/GenericModal';
import ModalToast from '~/components/common/modals/ModalToast';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';

type ModalToastState = {
  type: 'success' | 'error';
  show: boolean;
};

type Props = {
  url?: string;
  id?: number | string;
};

const ShareLinkModal = ({ id = '', url = '' }: Props) => {
  const { t } = useTranslation();
  const [modalToast, setModalToast] = useState<ModalToastState>({ type: 'success', show: false });

  const shareModalRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    mixpanelTrack('E.share_copy_link_true');
    navigator.clipboard
      .writeText(url || window.location.href)
      .then(() => {
        setModalToast({ type: 'success', show: true });
      })
      .catch(() => {
        setModalToast({ type: 'error', show: true });
      });
  };

  return (
    <>
      <GenericModal
        open={true}
        modalRef={shareModalRef}
        title={t('office.show.shareLink.modal.title')}
        id={`shareModal_${id}`}
        extraClasses="modal-xs"
        mobileFullScreen={false}
      >
        <div className="modal-body d-flex justify-content-center">
          <button
            className="share-link-btn w-100 fo-border-sm border-grey-200 rounded-3 p-3"
            onClick={() => handleClick()}
          >
            <div className="d-flex align-items-center">
              <div className="fo-border-sm border-grey-200 rounded-3 p-2 me-3 bg-white">
                <SVGIcon
                  iconType="copy-2"
                  width="40"
                  height="40"
                />
              </div>

              <div className="text-1 -regular">{t('office.show.shareLink.modal.cta')}</div>
            </div>
          </button>
        </div>
      </GenericModal>

      <ModalToast
        type={modalToast.type}
        text={t('office.show.shareLink.modal.toast.success')}
        show={modalToast.show}
        onHide={() => setModalToast({ type: 'success', show: false })}
      />
    </>
  );
};

export default ShareLinkModal;

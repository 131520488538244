import { DateTime } from 'luxon';
import { luxonFormatOptions } from '~/components/utils/date';
import { isOfficeAvailableNow } from '~/components/utils/officeData';
import { CoreOfficeDataType } from '~/types/offices';
import Badge, { Variant } from './Badge';
import { useLanguageParam } from '~/config/i18n';
import { useTranslation } from 'react-i18next';

const OfficeAvailabilityBadge = ({ office }: { office: CoreOfficeDataType }) => {
  const lang = useLanguageParam();
  const { t } = useTranslation();

  function formatDate(date: string, lang: 'fr' | 'en'): string {
    const parsedDate = DateTime.fromISO(date);
    let formatter = luxonFormatOptions.dayAndShortMonth[lang];
    if (parsedDate.diffNow('days').days >= 365) {
      formatter = luxonFormatOptions.dayAndShortMonthWithYear[lang];
    }
    return DateTime.fromISO(date).setLocale(lang).toFormat(formatter);
  }

  return (
    <>
      {isOfficeAvailableNow(office) ? (
        <Badge
          variant={Variant.PRIMARY_GHOST}
          icon="check-circle"
          iconFill="#35660D"
          text={t('office.availability.available')}
        />
      ) : (
        <Badge
          variant={Variant.PRIMARY_GHOST}
          icon="waitlist"
          iconFill="#706D78"
          text={formatDate(office.earliestStartDate, lang)}
        />
      )}
    </>
  );
};

export default OfficeAvailabilityBadge;
